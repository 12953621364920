<template>

<com-content class="services" :class="{'is-disabled': disabled}">

	<com-content :inner="true">

		<com-head title="Which services do you need?" text="Bundle all three services and save on your badge fee." icon="" />

		<com-content class="services-wrapper">

			<com-service v-for="(service, index) in services" :level="level" v-on:click="onServiceClick(service.id)" :count="value.length" :selected="$_.contains(value, service.id)" :key="index" :item="service" />

		</com-content>

	</com-content>

</com-content>

</template>

<script>

export default {

	props: ['value', 'level', 'services', 'disabled'],
	
	components: {

		'com-content': () => import('./../common/Content'),
		'com-service': () => import('./Service'),
		'com-head': () => import('./Head'),

	},

	methods: {

		onServiceClick: function(id) {

			var value = this.$util.copy(this.value)

			if (this.$_.contains(this.value, id)) {

				value.splice(value.indexOf(id), 1)

			} else {

				value.push(id)

			}

			this.$emit('input', value)

		}

	}

}

</script>

<style scoped>

.services {
	padding: 50px 0px 40px 0px;
}

.is-mobile .services {
	padding: 40px 10px 20px 10px;
}

.services.is-disabled {
	pointer-events: none;
}

.services.is-disabled > * {
	opacity: 0.25;
}

.services-wrapper {
	display: grid;
	grid-gap: 20px;
	grid-template-columns: 1fr 1fr 1fr;
}

.is-mobile .services-wrapper {
	grid-template-columns: 1fr;
}

</style>